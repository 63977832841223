// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-compound-interest-js": () => import("./../src/pages/compound-interest.js" /* webpackChunkName: "component---src-pages-compound-interest-js" */),
  "component---src-pages-ease-js": () => import("./../src/pages/ease.js" /* webpackChunkName: "component---src-pages-ease-js" */),
  "component---src-pages-flexbox-js": () => import("./../src/pages/flexbox.js" /* webpackChunkName: "component---src-pages-flexbox-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-svgs-js": () => import("./../src/pages/svgs.js" /* webpackChunkName: "component---src-pages-svgs-js" */)
}

